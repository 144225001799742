@import url("https://fonts.googleapis.com/css2?family=Oxygen&family=Oxygen+Mono&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  html {
    scrollbar-width: thin;
    scrollbar-color: #374151 #374151;
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  .dark ::-webkit-scrollbar-track {
    background: #111827;
  }
  .dark ::-webkit-scrollbar-thumb {
    background-color: #0f172a;
    border: 3px solid #374151;
    border-radius: 10px;
  }
  .light ::-webkit-scrollbar-track {
    background: white;
  }
  .light ::-webkit-scrollbar-thumb {
    background-color: #94a3b8;
    border: 3px solid #cbd5e1;
    border-radius: 10px;
  }
}

.chatbot__chat {
  display: flex;
}

.chatbot__chat p {
  max-width: 75%;
  font-size: 0.95rem;
  white-space: pre-wrap;
  color: #202020;
  background-color: rgb(6 182 212 / 1);
  border-radius: 10px 10px 0 10px;
  padding: 12px 16px;
}
.chatbot__chat p.error {
  color: #721c24;
  background: #f8d7da;
}
.incoming p {
  color: #202020;
  background: #bdc3c7;
  border-radius: 10px 10px 10px 0;
}
.incoming span {
  width: 32px;
  height: 32px;
  line-height: 32px;
  color: #f3f7f8;
  background-color: rgb(6 182 212 / 1);
  border-radius: 4px;
  text-align: center;
  align-self: flex-end;
  margin: 0 10px 7px 0;
}
.outgoing {
  justify-content: flex-end;
  margin: 20px 0;
}
.incoming {
  margin: 20px 0;
}

.chatbot__input-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  border-top: 1px solid rgb(6 182 212 / 1);
  background: #f3f7f8;
  padding: 5px 20px;
}
.chatbot__textarea {
  width: 100%;
  font-size: 0.95rem;
  padding: 5px;
  color: #202020;
  border: none;
  outline: none;
  resize: none;
  background: transparent;
}
.chatbot__textarea::placeholder {
  font-family: "Poppins", sans-serif;
}
.chatbot__input-box span {
  font-size: 1.75rem;
  color: rgb(6 182 212 / 1);
  cursor: pointer;
  visibility: hidden;
}
.chatbot__textarea:valid ~ span {
  visibility: visible;
}
